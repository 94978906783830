import { Grid, Typography } from "@mui/material";
import { OrderFrequency } from "Api/Api";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import { BlDropdown } from "Components/Shared/Inputs/BlDropdown";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { LookupItem } from "Models/LookupItem";
import { Resources, useResource } from "Translations/Resources";
import { getAllowedOrderFrequencies } from "Utils/Order/OrderFrequencyUtils";
import {
  RECURRING_INDEFINITE_ADD_YEARS,
  formatDate,
  getDateToOptions,
  getDefaultToDate,
  getInitDate,
  parseDate,
} from "Utils/Order/PaymentFrequencyUtils";
import { addDays, addYears } from "date-fns";
import { last } from "lodash-es";
import { useEffect, useState } from "react";
import { FieldPath, UseFormReturn } from "react-hook-form";
import styled from "styled-components";

const PageResources = Resources.Orders.Detail.Payment;

const Label = styled(Typography)`
  font-weight: 400 !important;
`;
const StyledCheckboxGrid = styled(Grid)`
  margin-left: ${props => props.theme.spacing(1)};
  margin-top: ${props => props.theme.spacing(3.5)};
`;

type Props = {
  form: UseFormReturn<OrderFormModel>;
};

export const PaymentFrequencyDateFields: React.FunctionComponent<
  Props
> = props => {
  const { form } = props;
  const {
    control,
    formState: { errors },
  } = form;
  const { watch, setValue } = form;

  const paymentPeriodFrom: FieldPath<OrderFormModel> =
    "order.paymentFrequencyDateFrom";
  const paymentPeriodTo: FieldPath<OrderFormModel> =
    "order.paymentFrequencyDateTo";

  const isIndefiniteEndName: FieldPath<OrderFormModel> =
    "order.isIndefiniteEnd";

  const isIndefiniteEnd = watch("order.isIndefiniteEnd");

  const dateFrom = watch("order.paymentFrequencyDateFrom") ?? getInitDate();
  const from = formatDate(dateFrom);

  const dateTo = watch("order.paymentFrequencyDateTo") ?? getInitDate();
  const to = formatDate(dateTo);

  const dateFromOptions: LookupItem[] = Array.from(
    { length: 60 },
    (_, i) => i,
  ).map(x => {
    return {
      Name: formatDate(addDays(getInitDate(), x)),
      Value: formatDate(addDays(getInitDate(), x)),
    };
  });

  const { t } = useResource();
  const paymentFrequency = watch("order.paymentFrequency");
  const orderFrequency = watch("order.frequency");

  const [dateToOptions, setDateToOptions] = useState(
    getDateToOptions(dateFrom, paymentFrequency, isIndefiniteEnd),
  );

  useEffect(() => {
    const newDateToOptions = getDateToOptions(
      dateFrom,
      paymentFrequency,
      isIndefiniteEnd,
    );
    setDateToOptions(newDateToOptions);

    if (isIndefiniteEnd) {
      setValue(
        paymentPeriodTo,
        addYears(addDays(dateFrom, -1), RECURRING_INDEFINITE_ADD_YEARS),
      );
    } else {
      const validToOption = newDateToOptions.find(
        option => option.Value === to,
      );
      const defaultTo = validToOption
        ? validToOption.Value
        : getDefaultToDate(dateFrom, paymentFrequency, isIndefiniteEnd);
      setValue(paymentPeriodTo, parseDate(defaultTo));
    }
  }, [dateFrom, isIndefiniteEnd, paymentFrequency, setValue, to]);

  const checkOrderFrequency = (from: Date, to: Date) => {
    const allowedOrderFrequencies = getAllowedOrderFrequencies(
      paymentFrequency,
      from,
      to,
    );

    if (!allowedOrderFrequencies.includes(orderFrequency)) {
      const newOrderFrequency =
        (last(allowedOrderFrequencies) as OrderFrequency | undefined) ??
        OrderFrequency.Weekly;

      setValue("order.frequency", newOrderFrequency);
    }
  };

  return (
    <Grid container gap={0.2} mt={1}>
      <Grid item xs={3.6}>
        <Label>{t(PageResources.PaymentFrequencyDate.From.Label)}</Label>
        <BlDropdown
          name={paymentPeriodFrom}
          options={dateFromOptions}
          value={from}
          onChange={value => {
            const parsedValue = parseDate(value as string);
            const from = parsedValue;
            setValue(paymentPeriodFrom, from);

            const to = parseDate(dateToOptions[0].Value);
            checkOrderFrequency(from, to);
            setValue(paymentPeriodTo, to);
          }}
        />
      </Grid>
      <Grid item xs={3.6}>
        <Label>{t(PageResources.PaymentFrequencyDate.To.Label)}</Label>
        <BlDropdown
          name={paymentPeriodTo}
          options={dateToOptions}
          value={to}
          disabled={isIndefiniteEnd}
          onChange={value => {
            const to = parseDate(value as string);
            checkOrderFrequency(dateFrom, to);
            setValue(paymentPeriodTo, to);
          }}
        />
      </Grid>
      <StyledCheckboxGrid item xs={4.2}>
        <BlCheckboxInput
          label={t(PageResources.isIndefiniteEnd.Label)}
          control={control}
          errors={errors}
          name={isIndefiniteEndName}
          style={{ marginBottom: 1 }}
        />
      </StyledCheckboxGrid>
    </Grid>
  );
};
